<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <v-col>
                <v-card color="blue lighten-5">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="officeModel" 
                                        :items="officeLists" 
                                        item-value="office_id" 
                                        item-text="office" 
                                        label="Office" 
                                        :disabled="disabled_region" 
                                        @change="(event) => updateSalesmanDropdown(event)" 
                                        clearable 
                                        prepend-icon="mdi-home-variant"
                                        >
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="salesmanModel" 
                                        :items="salesmanLists" 
                                        item-value="SalesId" 
                                        item-text="NamaSales"
                                        label="Select Salesman" 
                                        :disabled="disabled_region"  
                                        clearable 
                                        prepend-icon="mdi-account"
                                        >
                                    </v-autocomplete>
                            </v-col>
                            <!-- <div class="col-lg-3">
                                <v-dialog ref="dialog" 
                                v-model="dateStartModal" 
                                :return-value.sync="dateStartModel" 
                                persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" 
                                        dense readonly v-bind="attrs" v-on="on" 
                                        clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" 
                                    @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div> -->
                            <!-- <div class="col-lg-3">
                                <v-dialog ref="dialog2" v-model="dateEndModal" 
                                :return-value.sync="dateEndModel" 
                                persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" 
                                    :show-current="true" 
                                    @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="dialog"
                                    v-model="dateStartModal" 
                                    :return-value.sync="dateStartModel" 
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateStartModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" 
                                    @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="dateEndModal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateEndModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" 
                                    :show-current="true" 
                                    @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn class="mt-3" 
                                    color="info" elevation="2" large @click="getData()">Search</v-btn>
                                </v-col>  
                        </v-row>
                        <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Result</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <!-- <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div> -->
                                        <div class="row">
                                            <div class="col-6">
                                                <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                            <div class="col-6 text-right">
                                                <v-btn class="mt-3" color="green" elevation="2" small rounded @click="exportToExcel()" dark>EXPORT TO EXCEL</v-btn>
                                            </div>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.activity`]="{ item }">
                                    <v-chip v-if="item.presentasi != 'N'" class="ma-2" color="cyan darken-3" small >
                                        <span class="text-white">PENAWARAN PRODUK</span>
                                    </v-chip>
                                    <v-chip v-if="item.takingorder != 'N'" class="ma-2" color="cyan darken-3" small >
                                        <span class="text-white">TAKING ORDER</span>
                                    </v-chip>
                                    <v-chip v-if="item.info != 'N'" class="ma-2" color="cyan darken-3" small >
                                        <span class="text-white">INFO PROGRAM</span>
                                    </v-chip>
                                    <v-chip v-if="item.penagihan != 'N'" class="ma-2" color="cyan darken-3" small >
                                        <span class="text-white">PENAGIHAN</span>
                                    </v-chip>
                                    <v-chip v-if="item.maintenance != 'N'" class="ma-2" color="cyan darken-3" small >
                                        <span class="text-white">CUSTOMER VISIT</span>
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <!-- <v-btn class="mr-4" color="info" elevation="2" small rounded @click="showItemMap(item)" >MAP <v-icon right dark>mdi-google-maps</v-icon></v-btn> -->
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip class="ma-1" color="#26C6DA" elevation="2" dense v-on="on" small rounded @click="showItemMap(item)" dark><v-icon dark>mdi-google-maps</v-icon> </v-chip>
                                            </template>
                                            <span>Show Map</span>
                                        </v-tooltip>
                                    </td>
                                    <td v-if="item.img_url != 'empty'">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip class="ma-1" color="#283593" elevation="2" dense v-on="on" small rounded @click="showImageModal(item.img_url)" dark><v-icon dark>mdi-file-image</v-icon></v-chip>
                                            </template>
                                            <span>Show Image</span>
                                        </v-tooltip>
                                    </td>
                                </template>
                            </v-data-table>
                                    </div>
                                </div>
                            </div>
                    </v-container>
                </v-card>
            </v-col>
        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-body">
                    <div id="mapCanvas" style="height:500px;"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-xl" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                    <div class="modal-header">
                        <h4 class="modal-title">Sales Visit Feedback</h4>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12">
                            <v-textarea v-model="kacabFeedbackModel" label="KACAB FEEDBACK" dense prepend-icon="mdi-note-outline" color="white" dark :disabled="disabled_feedback1" ></v-textarea>
                        </div>
                        <div class="col-lg-12">
                            <v-textarea v-model="salesFeedbackModel" label="SALES FEEDBACK" dense prepend-icon="mdi-note-outline" color="white" dark :disabled="disabled_feedback2"></v-textarea>
                        </div>
                        <div class="col-lg-12">
                            <v-btn class="" color="success" elevation="2" block rounded @click="storeFeedback()" >SUBMIT</v-btn>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade modal-md" id="imgModal" tabindex="-1" aria-labelledby="imgModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content bgCustom3">
                    <div class="modal-body">
                        <v-img :src=imageUrl ></v-img>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Sales Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Visit Report',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            officeModel: '',
            officeLists: [],
            salesmanModel: '',
            salesmanLists: [],
            dateStartModel: '',
            dateEndModel: '',
            headers: [
                { text: 'SALESMAN', value: 'salesid', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER ID', value: 'customerid', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER NAME', value: 'namacustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ADDRESS', value: 'alamat', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CITY', value: 'city', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'GPS ADDRESS', value: 'lt_location', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ACTIVITY', value: 'activity', align: 'center', class: 'primary--text blue lighten-5' },
                { text: '', value: 'actions', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            dateStartModal: false,
            dateEndModal: false,
            mymap: '',
            kacabFeedbackModel: '',
            salesFeedbackModel: '',
            disabled_feedback1: true,
            disabled_feedback2: true,
            userGroup: '',
            tr_id: '',
            userGroup: null,
            appl_id: 'SALES ACTIVITY',
            imageUrl: '',
            exportToExcelLists: [],
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            document.querySelector(".cardResult").style.display = "none";
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesmanModel = res.data.sales[0].SalesId
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                }
                this.$store.dispatch('setOverlay', false)
            })
        },

        async getData(){

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.exportToExcelLists = []
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport/getData`, { 
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    this.exportToExcelLists = res.data.result
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })

        },

        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            }
        },

        async showItemMap(item){

            if (this.mymap != '') {
                this.mymap.off()
                this.mymap.remove()
            }
            $('#detailModal').modal('show')

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${item.sales_latitude}&lon=${item.sales_longitude}`)
            .then(res => {

                this.mymap = L.map('mapCanvas').setView([item.sales_latitude, item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KKA}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([item.sales_latitude, item.sales_longitude],
                    {draggable: false,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup(item.namacustomer)
                    .openPopup();

            })

        },

        async showFeedback(item){

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport/getFeedback?id=${item.tr_id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                $('#feedbackModal').modal('show')
                this.tr_id = item.tr_id
                this.kacabFeedbackModel = res.data.result[0].kacab_feedback
                this.salesFeedbackModel = res.data.result[0].sales_feedback
                if(this.userGroup == 'SALES KKA'){
                    this.disabled_feedback2 = false
                } else {
                    this.disabled_feedback1 = false
                }
            })

        },

        async storeFeedback(){
           this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/VisitReport/storeFeedback`, { 
                    group: this.userGroup ? this.userGroup : '',
                    tr_id: this.tr_id ? this.tr_id : '',
                    kacabFeedback: this.kacabFeedbackModel ? this.kacabFeedbackModel : '',
                    salesFeedback: this.salesFeedback ? this.salesFeedback : ''
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                Swal.fire({
                    text: 'Feedback submitted succesfully',
                    icon: 'success'
                })
                this.getData()
                $('#feedbackModal').modal('hide')
                this.$store.dispatch('setOverlay', false)
            })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

        showImageModal(imgUrl){

            console.log(imgUrl);

            $('#imgModal').modal('show')
            this.imageUrl = process.env.VUE_APP_URL + imgUrl

        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "Order-Report-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },


    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>